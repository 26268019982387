const Swiper = window.Swiper;
window.addEventListener('load', function () {
  new Swiper('.fullwidth-header__carousel', {
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 7500,
      disableOnInteraction: true,
    },

    pagination: {
      el: '.fullwidth-header__carousel__navigation .swiper-pagination',
      clickable: 'true',
    },

    navigation: {
      nextEl: '.fullwidth-header__carousel__navigation .swiper-button-next',
      prevEl: '.fullwidth-header__carousel__navigation .swiper-button-prev',
    },
  });
});
